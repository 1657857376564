import React from 'react'
import {Map as BaseMap, Marker, Popup, TileLayer} from "react-leaflet";
import {isDomAvailable} from "../lib/helpers";


const Map = ({children, position}) => {
    if (!isDomAvailable()) {
        return null;
    }

    const open = (e) => {
        console.log(e)
    }

    return (
        <BaseMap center={position} zoom={20} className='h-full'>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
                <Popup onOpen={open}>
                    {children}
                </Popup>
            </Marker>
        </BaseMap>
    )
}

export default Map;