import React from "react";
import {graphql} from "gatsby";
import Container from "../components/container";
import SEO from "../components/seo";
import Content from "../components/content";
import Map from "../components/map";
import ContactForm from "../forms/contact";
import Email from '../components/email'
import PageDecal from "../assets/page-decal.svg";

class ContactPage extends React.Component {

    render() {
        const ContactMap = () => (
            <div className='h-full md:pb-48 mt-20px'>
                <Map position={[62.5691609, 7.6866944]}>
                    <strong>iSi AS</strong>
                    <p className='body-text'>
                        Strandgata 6, 6300 Åndalsnes<br/>
                        <strong>E-post:</strong> <a href='mailto:post@isi.no'>post@isi.no</a><br/>
                        <strong>Telefon:</strong> +47 411 43 020<br/>
                        <strong>Org. nr.:</strong> 987356731
                    </p>
                </Map>
            </div>
        )


        return (
            <>
                <SEO
                    title='Kontakt oss'
                />
                <Container className='relative md:pb-48 mb-0 md:pt-24 z-30 md:px-24'>
                    <Content>
                        <div className="flex flex-col md:flex-row -mx-6">
                            <div className="flex-1 px-6 pt-6 pb-20">
                                <h2 className='text-3xl md:text-5xl mb-6 text-brand-red font-semibold leading-tight font-heading max-w-3xl tracking-tighter'>
                                    Kontakt oss
                                </h2>
                                <div className='text-body leading-normal mb-16'>
                                    <strong className='text-xl md:text-base'>iSi AS</strong><br/>
                                    Strandgata 6, 6300 Åndalsnes<br/>
                                    Telefon: +47 411 43 020<br/>
                                    Org. nr.: 987356731<br/><br/>
                                    <Email
                                        email={'post@isi.no'}
                                        className='group flex items-center'
                                        label={(
                                            <>
                                                <i className='fa fa-envelope text-brand-grey-lighter group-hover:text-brand-red text-xl mr-2'/>
                                                Send oss en epost
                                            </>
                                        )}
                                    />

                                </div>
                                <div className='relative'>
                                    <ContactForm/>
                                </div>
                            </div>
                            <div className="md:flex-1 h-hero md:h-64 md:h-auto md:px-6">
                                <ContactMap/>
                            </div>
                        </div>
                    </Content>
                </Container>
            </>
        );
    }
}

export default ContactPage;
