import React, {Component} from 'react'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {email: '', phone: '', name: '', body: '', submitted: false};
    }

    handleSubmit = e => {
        fetch("https://api.ibricks.no/v3/registrations?org=isi", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "formData": {
                        "e_post_adresse": {"alias": "E-post adresse", "value": this.state.email},
                        "informasjon": {"alias": "Informasjon", "value": this.state.body},
                        "mobiltelefon": {"alias": "Mobiltelefon", "value": this.state.phone},
                        "navn": {"alias": "Navn", "value": this.state.name}
                    }, "formId": "24FA696A6823B045C1258569004CEF29"
                }
            )
        })
            .then(() => {
                this.setState({submitted: true})
            })
            .catch(error => {
                console.log(error)
            });

        e.preventDefault();

    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <>

                {this.state.submitted && (
                    <div className='bg-brand-grey-light bg-opacity-50 p-8 text-center mb-64'>
                        <strong>Takk for henvendelsen.</strong> Vi svarer så fort vi har mulighet.
                    </div>
                )}
                {!this.state.submitted && (
                    <form className="-mx-2 flex flex-wrap" method="POST" onSubmit={this.handleSubmit}>
                        <div className="w-full px-2 mb-4">
                            <input
                                type="text"
                                name="name"
                                required
                                onKeyUp={this.handleChange}
                                placeholder="Navn"
                                className="w-full rounded-md bg-white placeholder-gray-700 border border-transparent transition-all duration-500 ease-in-out focus:border-gray-300 p-4 focus:shadow-lg"
                            />
                        </div>
                        <div className="mb-4 md:mb-0 w-full md:w-1/2 px-2">
                            <input
                                type="email"
                                name="email"
                                required
                                onKeyUp={this.handleChange}
                                placeholder="E-postadresse"
                                className="w-full rounded-md bg-white placeholder-gray-700 border border-transparent transition-all duration-500 ease-in-out focus:border-gray-300 p-4 focus:shadow-lg"
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2">
                            <input
                                type="text"
                                name="phone"
                                onKeyUp={this.handleChange}
                                placeholder="Telefon"
                                className="w-full rounded-md bg-white placeholder-gray-700 border border-transparent transition-all duration-500 ease-in-out focus:border-gray-300 p-4 focus:shadow-lg"
                            />
                        </div>
                        <textarea
                            placeholder="Skriv meldingen din her."
                            name="body"
                            required
                            onKeyUp={this.handleChange}
                            className="mx-2 mt-4 w-full rounded-md bg-white placeholder-gray-700 border border-transparent transition-all duration-500 ease-in-out focus:border-gray-300 p-4 focus:shadow-lg"
                            rows="5"
                        />
                        <div className='px-2 w-full md:flex md:items-center md:justify-end pt-8'>
                            <div className='w-full md:w-1/2 md:text-right text-sm mb-4 md:mb-0 md:mr-4'>
                                Ved å sende inn henvendelse tillater
                                du at iSi AS lagrer dine kontaktdata.
                            </div>
                            <button
                                type="submit"
                                className="transition duration-75 rounded-full ease-in-out bg-brand-red-light border-2 hover:text-white hover:bg-brand-red-lighter px-12 py-4 font-bold font-heading uppercase text-white"
                            >
                                Send melding
                            </button>
                        </div>
                    </form>
                )}
            </>
        )
    }

}

export default ContactForm;